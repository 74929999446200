import React, {Component} from 'react'
import { connect } from 'react-redux';
import '../assets/scss/components/Scoreboard.scss';

class Scoreboard extends Component {

    shouldComponentUpdate(nextProps) {
        if (this.props.scoreBoard !== nextProps.scoreBoard) {
            return true;
        }
        return false;
    }

    render(){
        return (
            <table className="scoreboard">
                <thead>
                    <tr><th colSpan="2">Scoreboard</th></tr>
                </thead>
                <tbody>
                {this.props.scoreBoard.map(score =>
                    <tr key={score.name}><td>{score.name}:</td><td>{score.score}</td></tr>
                )}
                </tbody>
            </table>
        );
    }

}

const mapStateToProps = state => ({
    scoreBoard: state.scoreBoard
});


export default connect(mapStateToProps)(Scoreboard);