import React, {Component} from 'react'
import { connect } from 'react-redux';
import '../assets/scss/components/GameStatus.scss';
import '../assets/scss/base/Buttons.scss';

class GameStatus extends Component {

    render(){

        let gameStatus;
        if (this.props.endOfGame) {
            gameStatus = (
                <div className='game-status'>
                    <span className='btn' onClick={this.props.dealCards}>New game</span>
                    <div className='game-status__text'>{this.props.winner}</div>
                </div>
            );
        } else if (this.props.maximumToSwapCards > 0) {
            gameStatus = (
                <div className='game-status'>
                    <span className='btn' onClick={this.props.endGame}>Show cards</span>
                    <div className='game-status__text'>You can swap {this.props.maximumToSwapCards} cards</div>
                </div>
            );
        } else {
            gameStatus = (
                <div className='game-status'>
                    <span className="btn btn--start-game" onClick={this.props.dealCards}>Start game</span>
                </div>
            )
        }

        return (
            gameStatus
        );
    }

}

const mapStateToProps = state => ({
    endOfGame: state.endOfGame,
    maximumToSwapCards: state.maximumToSwapCards,
    winner: state.winner
});

const mapDispatchToProps = dispatch => ({
    dealCards: () => dispatch({type: 'DEAL_CARDS'}),
    endGame: () => dispatch({type: 'END_GAME'})
});

export default connect(mapStateToProps, mapDispatchToProps)(GameStatus);