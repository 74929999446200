import React, {Component} from 'react'
import { connect } from 'react-redux';
import '../assets/scss/components/DealerCards.scss';
import '../assets/scss/components/Card.scss';

class DealerCards extends Component {

    render(){

        return (
            <div className={`dealer ${this.props.endOfGame ? 'dealer--show-cards' : ''}`}>
                <div className="dealer__name">Dealer</div>
                <div className="dealer__cards">
                    {this.props.dealerCards.map(card =>
                        <div className={`card card--${card}`}></div>
                    )}
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    dealerCards: state.dealerCards,
    endOfGame: state.endOfGame
});


export default connect(mapStateToProps)(DealerCards);