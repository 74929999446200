// Using pokersolver to check who has best hand.
import PokerSolver from 'pokersolver';

const initialState = {
    cards: [],
    dealerCards: [],
    playerCards: [],
    maximumToSwapCards: 0,
    endOfGame: false,
    winner: '',
    scoreBoard: [
        {'name': 'Dealer', 'score': 0},
        {'name': 'Player', 'score': 0}
    ]
}


const reducer = (state = initialState, action) => {

    // Declare the full deck of cards
    const deck = ['2s', '3s', '4s', '5s', '6s', '7s', '8s', '9s', 'Ts', 'Js', 'Qs', 'Ks', 'As',
        '2h', '3h', '4h', '5h', '6h', '7h', '8h', '9h', 'Th', 'Jh', 'Qh', 'Kh', 'Ah',
        '2d', '3d', '4d', '5d', '6d', '7d', '8d', '9d', 'Td', 'Jd', 'Qd', 'Kd', 'Ad',
        '2c', '3c', '4c', '5c', '6c', '7c', '8c', '9c', 'Tc', 'Jc', 'Qc', 'Kc', 'Ac'];

    let cards;
    let dealerCards;
    let playerCards;
    let maximumToSwapCards;

    switch (action.type) {
        case 'DEAL_CARDS':

            // Shuffle cards
            cards = deck.map(x => ({x, r: Math.random()}))
                .sort((a, b) => a.r - b.r)
                .map(a => a.x);


            // Get first 5 cards for dealer
            dealerCards = cards.slice(0, 5);
            // Get next 5 cards for player
            playerCards = cards.slice(6, 11);

            // Filter dealer and player cards from cards so they can't be dealt again
            cards = cards.filter( ( el ) => !dealerCards.concat(playerCards).includes( el ) );

            // Set a random number between 1 and 3 for maximum to swap cards.
            maximumToSwapCards = Math.floor(Math.random() * 3) + 1;

            return {
                ...state,
                cards: cards,
                dealerCards: dealerCards,
                playerCards: playerCards,
                maximumToSwapCards: maximumToSwapCards,
                endOfGame: false
            };

        case 'SWAP_CARD':

            // Only swap a card when maximum is not exceeded and game is not ended
            if (state.maximumToSwapCards > 0 && !state.endOfGame) {

                // Get cards
                cards = state.cards;

                // Get the card you want to swap
                let cardToSwap = action.card;

                // Get a new card
                let newCard = cards[0];

                // Remove new card from cards
                cards = cards.filter( ( el ) => !newCard.includes( el ) );

                // Swap the old card with the new card in the same place so hand won't shuffle.
                playerCards = state.playerCards.map(function(item) { return item === cardToSwap ? newCard : item; });

                // Update maximumtoswap cards
                maximumToSwapCards = state.maximumToSwapCards - 1;

                return {
                    ...state,
                    cards: cards,
                    playerCards: playerCards,
                    maximumToSwapCards: maximumToSwapCards
                }
            }
        case 'END_GAME':

            // Only run end of game once
            if (!state.endOfGame) {
                // Get dealer cards
                dealerCards = state.dealerCards;

                // Get player cards
                playerCards = state.playerCards;

                // Solve the dealers hand with the pokersolver lib
                dealerCards = PokerSolver.Hand.solve(dealerCards);
                // Set dealer as name of hand
                dealerCards.name = 'Dealer';

                // Solve the players hand with the pokersolver lib
                playerCards = PokerSolver.Hand.solve(playerCards);
                // Set player as name of hand
                playerCards.name = 'Player';

                // Compare hands with pokersolver
                let winner = PokerSolver.Hand.winners([playerCards, dealerCards]);

                // Set score
                let scoreBoard = state.scoreBoard;
                // Find the winner in the array
                const objIndex = scoreBoard.findIndex(obj => obj.name === winner[0].name);
                // Add the score to the winner
                const updatedObj = { ...scoreBoard[objIndex], score: scoreBoard[objIndex].score + 1};
                // Update the score array
                const updatedScoreBoard = [
                    ...scoreBoard.slice(0, objIndex),
                    updatedObj,
                    ...scoreBoard.slice(objIndex + 1),
                ];

                return {
                    ...state,
                    endOfGame: true,
                    winner: winner[0].name +  ' wins the game with ' + winner[0].descr,
                    scoreBoard: updatedScoreBoard
                }
            }
            break;
        default:
            return state


    }

    return state;
};

export default reducer;