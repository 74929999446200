import React, {Component} from 'react'
import { connect } from 'react-redux';
import Scoreboard  from '../components/scoreboard';
import GameStatus  from '../components/gamestatus';
import DealerCards  from '../components/dealercards';
import PlayerCards  from '../components/playercards';
import '../assets/scss/components/PokerGame.scss';

class PokerGame extends Component {


    componentDidUpdate() {
        if (this.props.maximumToSwapCards === 0) {
            this.props.endGame()
        }
    }

    render(){

        return (
          <div>
              <Scoreboard />

              <div className="board">

                  <DealerCards />

                  <GameStatus />

                  <PlayerCards />

              </div>
          </div>
        );
    }

}

const mapStateToProps = state => ({
    maximumToSwapCards: state.maximumToSwapCards,
    endOfGame: state.endOfGame
});

const mapDispatchToProps = dispatch => ({
    endGame: () => dispatch({type: 'END_GAME'})
});

export default connect(mapStateToProps, mapDispatchToProps)(PokerGame);