import React, {Component} from 'react'
import './assets/scss/base/App.scss';
import PokerGame from './containers/pokergame';

class App extends Component {

    render() {
        return (
            <div className="App">
                <PokerGame />
            </div>
        );
    }
}

export default App;

