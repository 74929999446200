import React, {Component} from 'react'
import { connect } from 'react-redux';
import '../assets/scss/components/PlayerCards.scss';
import '../assets/scss/components/Card.scss';



class PlayerCards extends Component {

    render(){

        return (
            <div className={`player ${this.props.endOfGame ? 'player--end-game' : ''}`}>

                <div className="player__cards">
                    {this.props.playerCards.map(card =>
                        <div className={`card card--${card}`} onClick={() => this.props.swapCard(card)}></div>
                    )}
                </div>
                <div className="player__name">Player</div>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    playerCards: state.playerCards,
    endOfGame: state.endOfGame
});

const mapDispatchToProps = dispatch => ({
    swapCard: (card) => dispatch({type: 'SWAP_CARD', card: card})
});


export default connect(mapStateToProps, mapDispatchToProps)(PlayerCards);
